import React from 'react';

import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';

class IndexPage extends React.Component {
  render() {
    return (
      <div>
        <SEO
          title="Home"
          keywords={['blog', 'gatsby', 'javascript', 'react']}
        />
        <Social>
          <a
            href="https://drive.google.com/file/d/1Lax3qpeR7naK3uY8hzlibOIy0hMy_9WQ/view"
            title="Straight to business"
          >
            CV
          </a>
          <a
            href="https://linkedin.com/in/sashakatin"
            title="Let me add you to my professional network"
          >
            Linkedin
          </a>
          <a
            href="https://github.com/lessthanzero"
            title="Some Github repos I’ve worked on"
          >
            Github
          </a>
          <a
            href="https://twitter.com/alexanderkatin"
            title="Trying and failing to tweet regularly"
          >
            Twitter
          </a>

        </Social>
        <Section className="tldr">
          <p>
            Senior product designer with over eight years of experience creating user interfaces, doing research and design direction. I am working on useful no-nonsense products, and I’m passionate about cross-function and design collaboration, ethics, knowledge sharing, clear communication, and self-awareness. I've worked with various companies helping them to achieve their business goals through the user-centred design process. Creator of plugins for Figma and Sketch. Mentoring junior designers in my spare time.
          </p>
          <p>I’m currently re-imagining the future of the supply chain at <a href='#beacon'>Beacon Technologies</a>. Previously: I worked on Avito’s <a href='#avito'>design system and internal services</a>, helped to create design tools at the <a href='#sympli'>startup</a>, and made cities <a href='#habidatum'>a bit smarter</a> at the data-science agency.
          </p>
          <p>
            I can help establish a design system, develop a product strategy, conduct user research, prototype and create user interfaces. Read further or take a look at my
            {' '}
            <a href="https://drive.google.com/file/d/1Lax3qpeR7naK3uY8hzlibOIy0hMy_9WQ/view">
              CV
            </a>
            .
          </p>
          <p>
            <span class="small">
            <span role="img" aria-label="earth showing Europe-Africa">
                &#x1F30D;
            </span>
              &nbsp;London, United Kingdom
              </span>
          </p>
        </Section>
        <Section id="beacon">
                  <h2>Beacon Technologies</h2>
                  <p>
                    <span class="small">
                    2021–now
                    </span>
                  </p>
                  <p>
                    I’m currently working on the tracking experience and global navigation.
                  </p>
                </Section>
        <Section id="avito">
          <h2>Working at the biggest Russian classified</h2>
          <p>
            <span class="small">
            2018–2021
            </span>
          </p>
          <p>
            I’ve designed several projects during my time at Avito. Right now, I can only share parts of my experience working on <a href='https://sashakatin.party/avito-colors'>Avito’s design system</a>. I helped to redesign internal services for 360 reviews and employee scoring.
          </p>
          <p>
            I designed and developed a Figma plugin called Narrator for internal usage and wrote <a href='https://habr.com/en/company/avito/blog/549786/'>an article</a> about it. In my semi-spare time, I dabbled in navigational design for the office. I also helped to launch and grow a series of design meetups hosted in the company. Five events have happened so far.
          </p>
          <p>
            <span class="case disabled">Cases upon request</span>
          </p>
        </Section>
        <Section id="sympli">
          <h2>Designing for designers</h2>
          <p>
            <span class="small">
            2017–2018
            </span>
          </p>
          <p>
            In 2017 I joined a remote Sympli team as a product designer. My main task was shipping a beta of Versions — a versioning system for Sketch designs, and improving the overall user experience of the core app.
          </p>
          <p>
            <a href="https://magnetic-yoke-02f.notion.site/Designing-for-designers-bfbdbcd678cc4fdbaa3d7c2dea42bb84" class="case">That's how we did it</a>
          </p>
        </Section>
        <Section id="habidatum">
          <h2>Making smarter cities</h2>
          <p>
            <span class="small">
            2016–2017
            </span>
          </p>

          <p>
            Moscow is a rapidly developing city with a massive demand for urban planning — architectural bureaus try to do extensive data research before embarking on a new costly project.
          </p>
          <p>
            <a href='https://magnetic-yoke-02f.notion.site/Making-cities-smarter-503383999291496b83f38ec60926d3b8' class="case">Learn how we helped them</a>
          </p>
        </Section>

        <Section id="design-developer">
          <h2>TookTook identity</h2>
          {/* <p>I&apos;ve done: web dev, animation</p> */}
          <p>
            Together with Sergey Tarasenko, we’ve made an animated identity for the TookTook agency website. It was fun diving into web animations done with vanilla JavaScript and a couple of tiny libs. The client agency was soon acquired, and we could never finish the optimization work.
          </p>
          <p>
            <a href="https://vimeo.com/163300156" class="case">Watch the video</a>
          </p>
        </Section>
        <Section >
            <h2>Exploration of an everyday tool</h2>
            <p>
              I've designed and developed the plugin setting the proportions for all types of layers in Sketch. My main goal was to explore the Sketch plugins' ecosystem thoroughly. Formr keeps working with the latest Sketch updates primarily due to the code's simplicity. I've also written a comprehensive <i>readme</i>.
            </p>
            <p>
              <a href="https://github.com/lessthanzero/Formr" class="case">Github repo</a>
            </p>
        </Section>
        <Section>
          <h2>Public gigs</h2>
          <p>
            “Award-winning intranet: From Russia with love”, <a href='https://intrateam.dk/events/intrateam-event-online-2021'>IntraTeam Event Online</a>, 2021
          </p>
          <p>
            Panel discussion “Why do designers need side-gigs?”, <a href='https://youtu.be/Da76M0gkUM0'>Avito Design Talk 2</a>, 2020
          </p>
          <p>
            "<a href='https://www.youtube.com/watch?v=D37MEvB1bn0'>How we removed unnecessary colours from the production build</a>", 2019, a talk at Avito Design Meetup
          </p>
          <p>
            "Creating digital products", 2019, one-day workshop for Product University
          </p>
          <p>
            For 3+ years, I led <a href="http://processing.org">Processing</a> courses for designers at <a href='https://bangbangeducation.ru'>BangBang Education</a>, <a href='https://moscoding.ru'>Moscow Coding School</a>, and <a href='https://yandex.ru'>Yandex</a>.
          </p>
        </Section>
        <Section id="teaching">
                  <h2>Everything else</h2>
                  <p>
                    <span class="small">
                    2020–2021
                    </span>
                  </p>
                  <p>
                  As a side-gig with <a href="https://www.typical.company">Typical.HR</a>, I’ve conducted four dozen scoring interviews with designers of various seniority levels and wrote a comprehensive review of each candidate
                  </p>
                  <p>
                    <span class="small">
                    2021
                    </span>
                  </p>
                  <p>
                  I finished an online course “Typography and layouts” by Mikhail Nozik, <a href="http://bureau.rocks">Bureau</a>, graduation <a href="https://bureau.ru/courses/certificate/4216aa658b1e1a05a0a98e3d51033fd61413772/">certificate</a>
                  </p>
                  <p>
                    <span class="small">
                    2012–2014
                    </span>
                  </p>
                  <p>
                  I studied Interactive and New Media at Moscow's most prominent <a href="https://britishdesign.ru/en/">design school</a>. Still proud of the D&AD honourable mention for the BBC design challenge solution that I got back in 2013.
                  </p>
                </Section>
      </div>
    );
  }
}

const Section = styled.section`
  padding: 0;

  &:first-child {
    margin: 8rem 0 2rem;
  }

  &.typeA,
  &.typeB,
  &.typeC {
    border-radius: 0.5rem;
    padding: 1rem;
  }

  &.typeA {
    background: #ffd84d;
  }

  &.typeA p,
  &.typeB p,
  &.typeC p {
    color: #000;
  }

  & > img,
  & > iframe {
    width: 100%;
    margin: 4rem 0;
    border-radius: .75rem;
  }

  &.tldr {
    margin: 2.5rem 0 6rem;
  }

  h2 {
    text-align: left;
  }

  &.secondaryHolder {
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  & .secondary {
    border-radius: 0.5rem;
    padding: 1.5rem;
    background: rgba(232, 233, 239, 1);
    width: 28rem;
  }

  @media (max-width: 1024px) {
    &.secondaryHolder {
      flex-direction: column;
    }

    & .secondary {
      width: 100%;
      margin: 0 0 3rem;
    }
  }

  .secondary h2,
  .secondary p {
    text-align: left;
    color: #000;
    padding: 0;
  }

  & .case {
    font-family: "Mabry", Helvetica, Arial, sans-serif;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0.375rem 1.25rem;
    font-size: 1.5rem;
    border: 2px solid #DEDEDE;
    border-radius: 1.5rem;
    color: #050505;
    text-decoration: none;
  }

  & .case.disabled {
    color: #C0C0C0;
  }

  .secondary a {
    color: #050505;
    text-decoration: none;
    border-bottom: 2px solid #C0C0C0;
  }

  .secondary a:hover {
    border-bottom: 2px solid #050505;
  }

  .foobar {
    display: inline;
    padding: 0.1rem 0.25rem;
    border-radius: 0.25rem;
    border: none;
    background: rgba(232, 233, 239, 0.5);
  }

  .foobar:hover {
    background: rgba(255, 216, 77, 0.5);
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;

  & a {
    font-family: "Mabry", Helvetica, Arial, sans-serif;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 2.5rem;
    padding: 0.375rem 1.25rem;
    font-size: 1.5rem;
    border: 2px solid #DEDEDE;
    border-radius: 1.5rem;
    color: #050505;
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    margin: 1rem 0 0;

    & a {
      margin: 0 3rem 0 0;
    }

  }

  @media (max-width: 768px) {
    & a {
      margin: 0 1.5rem 0 0;
    }
  }

  @media (max-width: 414px) {
    flex-wrap: wrap;

    & a {
      margin: 1rem 1rem 0 0;
    }
  }

  & a img {
    width: 100%;
    height: 100%;
    filter: brightness(0) invert(0);
    display: none;
  }

  & a:hover img {
    filter: none;
  }
`;

export default IndexPage;
